<template>
  <div style="min-height: 500px">
    <!-- Calendly inline widget begin -->
    <div v-if="isLoading" class="loader">
      One moment getting calendar ready...
    </div>
    <div class="calendly-inline-widget"></div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      value: "",
      context: null,
      isLoading: false,
    };
  },
  // mounted
  mounted() {
    this.isLoading = true;
    Calendly.initInlineWidget({
      url: "https://calendly.com/hydro-h",
      parentElement: document.querySelector(".calendly-inline-widget"),
      prefill: {},
      utm: {},
    });
    window.addEventListener("message", this.handleCalendy);
  },

  methods: {
    onContext(ctx) {
      this.context = ctx;
    },
    handleCalendy() {
      this.isLoading = false;
      if (e.data.event && e.data.event.indexOf("calendly") === 0) {
        console.log("EVENT", e.data);
      }
    },
  },
};
</script>

<style lang="css">
.calendly-inline-widget {
  min-width: 400px;
  height: 700px;
}
</style>