<template>
  <div style="min-height: 400px">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === null">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found in your selected items. Check
        <b-link class="alert-link" to="/apps/products"> Products </b-link>
        for other items.
      </div>
    </b-alert>
    <div v-if="product">
      <div class="mx-1">
        Welcome to Automatum! We have listed all your files for this product.
        You can download them and use them accordingly. Thank you for trusting
        us.
      </div>
      <div class="mb-1 mx-1 mt-1" style="font-size: 16px">
        PRODUCT NAME: <span style="font-weight: bold">{{ product.name }}</span>
      </div>
      <div v-if="isLoading" class="loader mx-1" style="font-size: 12px">
        Processing...
      </div>
      <b-list-group flush>
        <b-list-group-item
          v-if="product.document_url_1"
          class="d-flex justify-content-between align-items-center"
          ><span>Solution Design</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            @click="downloadAsset(product.document_url_1)"
            variant="primary"
          >
            Download
          </b-button></b-list-group-item
        >
        <b-list-group-item
          v-if="product.document_url_2"
          class="d-flex justify-content-between align-items-center"
          ><span>Operational Guide</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            @click="downloadAsset(product.document_url_2)"
            variant="primary"
          >
            Download
          </b-button></b-list-group-item
        >
        <b-list-group-item
          v-if="product.template_url"
          class="d-flex justify-content-between align-items-center"
          ><span>{{ product.name }} Template</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            @click="downloadAsset(product.template_url)"
            variant="primary"
          >
            Download
          </b-button></b-list-group-item
        >
      </b-list-group>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { generateSignedUrl } from "@/aws/Api";
import store from "@/store";
export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    product: function () {
      return store.state["app-products"].selectedProduct;
    },
  },
  methods: {
    async downloadAsset(event) {
      try {
        this.isLoading = true;
        const url = new URL(event)
        const response = await generateSignedUrl(url.pathname.replace(/^\/+/g, ''));
        window.open(response,'_blank');
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully downloaded your assets!`,
          },
        });
      } catch (error) {
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `An error occurred while downloading your assets! ${error}`,
          },
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
 