<template>
  <div class="product_container">
    <b-card style="min-height: 100%">
      <div style="height: 95%">
        <b-tabs v-model="tabIndex">
          <b-tab active>
            <template #title>
              <span>Product description</span>
            </template>
            <ProductOverview />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Design Documents</span>
            </template>
            <ProductAssets01 />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Infrastructure As Code</span>
            </template>
            <ProductAssets02 />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Help</span>
            </template>
            <ProductHelp />
          </b-tab>
        </b-tabs>
      </div>

      <div class="button_section">
        <div>
          <b-button
            v-if="tabIndex > 0"
            @click="tabIndex--"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Previous
          </b-button>
        </div>
        <div>
          <b-button
            v-if="tabIndex < 3"
            @click="tabIndex++"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Next
          </b-button>
          <b-button
            v-if="tabIndex === 3"
            @click="$router.push('/apps/products')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Finish
          </b-button>
        </div>
      </div>
    </b-card>

     <div class="product_alert" v-if="tabIndex < 3">
      <b-alert variant="secondary" show>
        <div
          class="alert-body"
          style="
            display: flex;
            padding: 1rem;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <p style="font-size: 16px">Do you need a custom license!</p>
            <p>
              If you have a mass production demand and other custom use cases
              than what we offer. We are here to help you.
            </p>
          </div>
          <b-button
            @click="$router.push({ name: 'apps-contact' })"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          >
            <span>&nbsp;Contact us</span>
          </b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BCard, BAlert, BButton, BTabs, BTab } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ProductOverview from "../product-details/ProductOverview.vue";
import ProductHelp from "../product-details/ProductHelp.vue";
import ProductAssets01 from "../product-details/ProductAssets01.vue";
import ProductAssets02 from "../product-details/ProductAssets02.vue";

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BTabs,
    BTab,
    // child components
    ProductOverview,
    ProductHelp,
    ProductAssets01,
    ProductAssets02,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 1,
    };
  },
};
</script>

<style lang="css">
.product_container {
   min-height: 100vh;
}
@media only screen and (min-width: 1200px) {
  .product_alert {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    left: 0px;
    right: 0px;
  }
}
@media only screen and (max-width: 1200px) {
  .product_alert {
    width: 100%;
  }
}
.button_section {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
</style>