<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === null">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found in your selected items. Check
        <b-link class="alert-link" to="/apps/products"> Products </b-link>
        for other items.
      </div>
    </b-alert>

    <b-card-body v-if="product">
      <b-row class="my-2">
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div v-if="product.image_url">
              <b-img
                :src="product.image_url"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </div>
        </b-col>

        <!-- Right: Product Details -->
        <b-col cols="12" md="7">
          <!-- Product Name -->
          <h4>{{ product.name }}</h4>
          <!-- Product Description -->
          <b-card-text v-html="product.description">{{
            product.description
          }}</b-card-text>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import store from "@/store";
export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    product: function () {
      return store.state["app-products"].selectedProduct;
    },
  },
};
</script>